<template>
    <v-card
        class="article pa-0 ma-0"
        width="100%"
        @click="showFirst = !showFirst; overlay = false"
    >
        <div :class="showFirst ? 'areasSimple' : 'areasExtend'">
            <v-card-subtitle
                style="grid-area: aTitle;"
                class="titleArticle text-h7 my-0 pa-0 mx-4 mt-2"
            >
                <span class="text-h6 my-0 pa-0">{{ showFirst ? item.title : item.title2 }}</span>
            </v-card-subtitle>
            <div
                style="grid-area: aDesc;"
                class="ql-editor text-gray-600"
                v-html="showFirst ? item.body : item.body2"
            />

            <img
                style="grid-area: aImg; align-self: center"
                class="imageArticle my-1 mr-2"
                :src="showFirst || !item.img2.url ? item.img.url : item.img2.url"
                @click.stop="overlay = true"
            >
        </div>
        <!--      for show the image in full mode when clic it-->
        <v-overlay
            z-index="99"
            :value="overlay"
        >
            <div
                class="d-flex close-image"
                @click.stop="overlay = false"
            >
                <v-icon
                    color="#fff"
                >
                    mdi-close
                </v-icon>
            </div>

            <v-img
                class="my-1 mr-1"
                :src="showFirst || !item.img2.url ? item.img.url : item.img2.url"
                max-width="90vw"
                max-height="90vh"
                contain
                @click.stop="overlay = false"
            />
        </v-overlay>
    </v-card>
</template>

<script>
export default {
    name: 'ArticleSingle',
    props: {
        article: {
            type: Object,
            require: true
        }
    },
    data: function () {
        return {
            item: this.getItem(),
            showFirst: true,
            overlay: false
        }
    },
    methods: {
        getItem() {
            const item = this.article

            var empty = !item

            return {
                id: empty ? '' : item.id,
                title: empty ? '' : item.attributes.title,
                body: empty ? '' : item.attributes.body,

                title2: empty ? '' : item.attributes.title2,
                body2: empty ? '' : item.attributes.body2,

                order: empty ? '' : item.attributes.order,
                img: {
                    value: empty || !item.attributes.image1 ? '' : item.attributes.image1,
                    url: empty || !item.attributes.image1 ? ''
                        : process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=articles/images/' + item.attributes.image1
                },
                img2: {
                    value: empty || !item.attributes.image2 ? '' : item.attributes.image2,
                    error: '',
                    url: empty || !item.attributes.image2 ? ''
                        : process.env.VUE_APP_API_ENDPOINT + 'imagesSimple?filename=articles/images/' + item.attributes.image2
                }
                /* url: {
              value: empty ? '' : item.attributes.url,
              error: ''
          } */
            }
        }
    }
}
</script>

<style lang="scss">

.article {
  .areasSimple {
    display: grid;
    grid-template-columns: auto min(120px, 40%);
    grid-template-areas:
    "aTitle aImg"
    "aDesc aImg ";

    .imageArticle {
      justify-self: end;
    }
  }

  .areasExtend {
    display: grid;
    grid-template-areas:
    "aTitle"
    "aImg"
    "aDesc";

    .titleArticle {
      justify-self: center;
    }

    .imageArticle {
      max-width: 150px;
      justify-self: center;
    }
  }

  .close-image{
    justify-content: flex-end;
  }

  @media (min-width: 700px) {
    .close-image{
      justify-content: center;
    }

  }

  .ql-editor  {
    ol, ul {
      padding-left: 0.2em !important;
    }
  }
}

</style>
