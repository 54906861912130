<template>
    <v-container>
        <div
            class="h5 mb-2 text-gray-700"
        >
            {{ $t('articles.title') }}
        </div>

        <v-toolbar

            dark
            color="primary"
            class="mb-3  rounded-lg"
        >
            <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                :label="$t('common.search')"
            />
        </v-toolbar>

        <transition-group
            name="slide-fade"
            tag="p"
        >
            <div
                v-for="item in dataFilter"
                :key="item.id"
                class="mb-4 pb-0"
            >
                <articleSingle :article="item" />
                <!--                <purposeGroupSingle
                    class="mt-0 pt-0"
                    :purpose="group"
                    style="grid-column: 1 / 3"
                />

                <v-divider
                    class="mx-6"
                    style="grid-column: 1 / 3"
                />-->
            </div>
        </transition-group>

        <nextPostBtn :url-next-post="nextPost" />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import articleSingle from '@/components/articles/articleSingle'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'MarketPlace',
    components: { articleSingle, nextPostBtn },
    mixins: [postMixin],
    data() {
        return {
            urlMain: '/articles',
            items: [],
            myPurposeCloud: undefined,
            search: ''
        }
    },
    computed: {
        dataFilter() {
            const search = this.search?.toUpperCase();

            let values = this.items
            if (search) {
                values = values.filter(child => (child.attributes.title && child.attributes.title.toUpperCase().includes(search)) ||
                    (child.attributes.body && child.attributes.body.toUpperCase().includes(search))
                );
            }

            return values;
        }
    },
    created() {
        this.fetchArticles();
    },
    methods: {

        async fetchArticles() {
            try {
                const articles = await axios
                    .get(this.urlMain);

                /* console.log('*********** roles **********')
    console.log(roles) */
                this.initialize(articles.data)
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            }
        },
        initialize($data) {
            this.items = $data.data.sort((a, b) => a.attributes.order >= b.attributes.order ? 1 : -1)
        }
    }
}
</script>

<style scoped>

</style>
